export const onMnemonicChange = /* GraphQL */ `
  subscription OnMnemonicChange($datasetId: ID!) {
    onMnemonicChange(datasetId: $datasetId) {
      id
      state
    }
  }
`;

export const onBerthChange = /* GraphQL */ `
  subscription OnBerthChange {
    onBerthChange {
      id
      state
      data
      updatedAt
    }
  }
`;