import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import Page from "./Page";
import { useDispatch, useSelector } from "react-redux";
import { setMnemonics } from "./redux/mnemonicsSlice";
import { setBerths } from "./redux/berthsSlice";
import { onMnemonicChange, onBerthChange } from "./api/subscriptions";
import { API, graphqlOperation } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 5,
  },
}));

const View = (props) => {
  const { history } = props;
  const { datasetId, viewId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mode, setMode] = useState();
  const [frames, setFrames] = useState();
  const dataset = useSelector((state) => state.dataset);
  const [dataDefaults, setDataDefaults] = useState();

  useEffect(() => {
    setMode(dataset.mode);
    setDataDefaults(dataset.properties);
    if (dataset.views) {
      const view = dataset.views.filter(v => v.id === parseInt(viewId, 10))[0];
//      setFrames(view.frames.filter(f => f.type === "Track"));
      setFrames(view.frames.filter(f => f.id === view.dominantFrame));
    } else {
      setFrames([{
        id: 0,
        x: 0,
        y: 0,
        index: viewId
      }]);
    }
  }, [dataset, viewId]);

  //subscribe to changes in berth values
  useEffect(() => {
    console.log("start subscription to berths");
    const subscriber = API.graphql(graphqlOperation(onBerthChange)).subscribe({
      next: (response) => {
        dispatch(setBerths({ [response.value.data.onBerthChange.id]: { state: response.value.data.onBerthChange.state, updatedAt: response.value.data.onBerthChange.updatedAt, data: response.value.data.onBerthChange.data} }));
      },
      error: (error) => {
        console.log("error on berths subscription", error);
      }
    });
    return () => {
      console.log("terminating subscription to berths");
      subscriber.unsubscribe();
    }
  }, [dispatch]);

  //subscribe to changes in mnemonic values
  useEffect(() => {
    console.log("start subscription to mnemonics");
    const subscriber = API.graphql(graphqlOperation(onMnemonicChange, {datasetId: datasetId})).subscribe({
      next: (response) => {
        dispatch(setMnemonics({ [response.value.data.onMnemonicChange.id.substring(datasetId.length+1)]: parseInt(response.value.data.onMnemonicChange.state, 10) }));
      },
      error: (error) => {
        console.log("error on mnemonics subscription", error);
      }
    });
    return () => {
      console.log("terminating subscription to mnemonics");
      subscriber.unsubscribe();
    }
  }, [dispatch, datasetId]);

  return (
    <>
      <div className={classes.fab}>
        <Fab color="primary" aria-label="home">
          <HomeIcon onClick={() => history.push(`/${datasetId}/${mode}`)} />
        </Fab>
      </div>
      {frames && frames.map((frame) => (
        <Page key={frame.id} x={frame.x} y={frame.y} h={dataDefaults.svgHeight} w={dataDefaults.svgWidth} datasetId={datasetId} pageId={frame.index} defaultColor={dataDefaults.defaultColor} {...props} />
      ))}
    </>
  );
}

export default View
