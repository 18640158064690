import { createSlice } from "@reduxjs/toolkit";

const datasetSlice = createSlice({
  name: "dataset",
  initialState: {},
  reducers: {
    setDataset: (state, action) => ({...action.payload}),
  },
});

export const { setDataset } = datasetSlice.actions;

export default datasetSlice.reducer;
