/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:e689a7a9-2f16-4932-943b-de42489633ff",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_y0DvCLZot",
    "aws_user_pools_web_client_id": "66jmqhg5j5u3bjrn7sjugdku0r",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://i5uk7dcjtfh6jiaj72s7tazuga.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-tpi3cmewtfhabpivcv3qnqya3q",
    "aws_user_files_s3_bucket": "webcad82139-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
