import { createSlice } from "@reduxjs/toolkit";

const mnemonicsSlice = createSlice({
  name: "mnemonics",
  initialState: {},
  reducers: {
    setMnemonics: (state, action) => ({...state, ...action.payload}),
    clearMnemonics: () => ({}),
  },
});

export const { setMnemonics, clearMnemonics } = mnemonicsSlice.actions;

export default mnemonicsSlice.reducer;
