import { createSlice } from "@reduxjs/toolkit";

const elementsSlice = createSlice({
  name: "elements",
  initialState: {},
  reducers: {
    setElements: (state, action) => ({...state, ...action.payload}),
    clearElements: () => ({}),
  },
});

export const { setElements, clearElements } = elementsSlice.actions;

export default elementsSlice.reducer;
