import { configureStore, combineReducers } from "@reduxjs/toolkit";
import datasetReducer from "./datasetSlice";
import mnemonicsReducer from "./mnemonicsSlice";
import elementsReducer from "./elementsSlice";
import berthsReducer from "./berthsSlice";

const reducer = combineReducers({
  dataset: datasetReducer,
  mnemonics: mnemonicsReducer,
  elements: elementsReducer,
  berths: berthsReducer,
});

const store = configureStore({
  reducer
});

export default store;