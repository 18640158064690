import React from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Amplify from "aws-amplify";
import Datasets from "./Datasets";
import Mode from "./Mode";
import View from "./View";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const App = (props) => {
  return (
    <Switch>
      <Route exact path="/">
        <Datasets {...props} />
      </Route>

      <Route exact path="/:datasetId/pages">
        <Mode {...props} mode="page" linkField="id" />
      </Route>
      <Route exact path="/:datasetId/views">
        <Mode {...props} mode="view" linkField="id" />
      </Route>
      <Route exact path="/:datasetId/screens">
        <Mode {...props} mode="screen" linkField="initialView" />
      </Route>

      <Route exact path="/:datasetId/view/:viewId">
        <View {...props} />
      </Route>

    </Switch>
  );
}

export default withAuthenticator(withRouter(App))