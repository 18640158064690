import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { API, graphqlOperation } from "aws-amplify";
import { listDatasets } from "./api/queries";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    flexGrow: 1,
  },
  header: {
    textAlign: "center"
  },
  width: {
    width: "20%",
  },
  margin: {
    margin: theme.spacing(1),
    cursor: "context-menu"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  input: {
    display: "none",
  },
}));

const Datasets = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    const initDatasets = async () => {
      console.log("fetching datasets");
      try {
        const response = await API.graphql(graphqlOperation(listDatasets));
        console.log(`Fetched ${response.data.datasets.length} datasets...`);
        if (response.data && response.data.datasets) {
          setDatasets(response.data.datasets);
          setLoading(false);
        }
      }
      catch (error) {
        console.log("error fetching datasets", error);
        setLoading(false);
      }
    };
    initDatasets();
  }, []);

  return (
    <>
      <Helmet>
        <style>{`body { background-color: black}; }`}</style>
      </Helmet>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <Grid container alignItems="center" direction="column" spacing={2}>
            <Grid className={classes.width}>
              <Typography variant="h4" className={classes.header}>Choose from the list of available GPC files:</Typography>
            </Grid>
            {datasets.map((dataset) => {
              return (
              <Grid key={dataset.id} className={classes.width}>
                <Button
                  className={classes.margin}
                  size="large" 
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push(`/${dataset.id}/${dataset.mode}`)}
                >
                  {dataset.name}
                </Button>
              </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Datasets
