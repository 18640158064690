import { createSlice } from "@reduxjs/toolkit";

const berthsSlice = createSlice({
  name: "berths",
  initialState: {},
  reducers: {
    setBerths: (state, action) => ({...state, ...action.payload}),
    clearBerths: () => ({}),
  },
});

export const { setBerths, clearBerths } = berthsSlice.actions;

export default berthsSlice.reducer;
