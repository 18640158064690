import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PNGImage from "pnglib-es6";
import { Helmet } from "react-helmet";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { setElements } from "./redux/elementsSlice";
import { Storage } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const chunkString = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);
  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }
  return chunks;
}

const base64Png = (data) => {
  try {
    const splitTwos = chunkString(data.hexadecimal, 2);
    splitTwos.unshift("zz"); // add dummy entry to start of array due to reduce method skipping the first element.
    const binString = splitTwos.reduce((concat, value) => (concat + ("00000000" + parseInt(value, 16).toString(2)).substr(-8)));
    const splitRows = chunkString(binString.substring(2), (Math.ceil(data.width/8.0)*8)); // remove the dummy entry again to ensure accurate conversion result.
    const image = new PNGImage(data.width, data.height, 8);
    const color = image.createColor(data.hexColor);
    for (var y = 0; y < splitRows.length; y++) {
      
      for (var x = 0; x < data["width"]; x++) {
        if (splitRows[y][x] === "1") {
            image.setPixel(x, y, color);
        }
      }
    }
    return image.getDataURL();
  }
  catch (error) {
    console.log(error);
  }
}

const Page = (props) => {
  const { x, y, h, w, datasetId, pageId, defaultColor, history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [berths, setBerths] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageDefaults, setPageDefaults] = useState();
  const [colors, setColors] = useState();
  const dataset = useSelector((state) => state.dataset);
  const elements = useSelector((state) => state.elements);
  const m = useSelector((state) => state.mnemonics);
  const b = useSelector((state) => state.berths);

  useEffect(() => {
    setColors(dataset.colors);
    setPageDefaults(dataset.pages.filter(p => p.id === parseInt(pageId, 10))[0].properties);
    setBerths(dataset.berths.filter(b => b.pageId === parseInt(pageId, 10)))
  }, [dataset, pageId])

  useEffect(() => {
    const initElements = async () => {
      if (!elements.hasOwnProperty(pageId)) {
        try {
          console.log(`Downloading: ${datasetId}/${pageId}.json...`)
          const result = await Storage.get(`${datasetId}/${pageId}.json`, {
            download: true,
            progressCallback(progress) {
              console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
            }
          });
          result.Body.text().then(content => {
            const jsonContent = JSON.parse(content);
            const elemArray = jsonContent.elements;
            console.log(`Fetched ${elemArray.length} elements...`);
            dispatch(setElements({ [pageId]: elemArray }));
            setLoading(false);
          });
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      } else {
        console.log("Elements already downloaded...");
        setLoading(false);
      }
    };
    initElements();
  })

  const mnemonicsToDecimal = (mnemonicsArray) => {
    const binStr = mnemonicsArray.reduce((binaryString, val) => {
      binaryString = (m[val] ? m[val].toString() : "0") + binaryString;
      return binaryString;
    }, "");
    return parseInt(binStr, 2) || 0;
  };

  const buttonClick = (button) => {
    setLoading(true);
    history.push(`/${datasetId}/view/${button}`);
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="primary" size="10em" disableShrink />
      </Backdrop>
      {colors && pageDefaults &&
        <>
          <Helmet>
            <style>{`body { background-color: ${colors[defaultColor].hex}}; }`}</style>
          </Helmet>
          <div style={{ fontSize: pageDefaults.defaultFontSize, fontFamily: pageDefaults.defaultFontFamily, fontWeight: pageDefaults.defaultFontWeight, cursor: "crosshair", position: "absolute", left: `${x}px`, top: `${y}px` }}>
            <svg width={w} height={h}>
            {elements[pageId] && elements[pageId].map((elem) => {
              const parameters = { ...elem.parameters };
              const stateValue = elem.colors.length === 1 ? 0 : (elem.mnemonics ? mnemonicsToDecimal(elem.mnemonics) : 0);
              const color = colors[elem.colors[stateValue]];
              for (const param of elem.colorParameters) {
                parameters[param] = color.hex;
              };
              if (elem.type === "image") {
                parameters["href"] = base64Png({
                  hexadecimal: elem.hexadecimal,
                  width: parameters.width,
                  height: parameters.height,
                  hexColor: color.hex
                });
              }
              const SvgTag = elem.type;
              return (
                <SvgTag key={elem.id} id={elem.id} {...parameters} visibility={color["state"] === 1 ? "visible" : "hidden"} onClick={elem.button != null ? () => buttonClick(elem.button) : undefined} style={elem.button != null ? {cursor: "pointer"} : undefined} >
                  {elem.value}
                  {color.state === 2 && <animate attributeName="visibility" from="visible" to="hidden" calcMode="discrete" dur="1s" repeatCount="indefinite" />}
                </SvgTag>
              )
            })}
            </svg>
            {berths && berths.filter(x => b.hasOwnProperty(x.id)).map((elem) => {
              const inlineStyle = {
                color: colors[elem.color].hex,
                backgroundColor: "#000000",
                zIndex: 2,
                position: "absolute",
                left: elem.x,
                top: elem.y,
                ...elem.properties
              }
              const updatedAt = moment(new Date(b[elem.id].updatedAt)).format("LTS");
              const data = b[elem.id].data ? JSON.stringify(JSON.parse(b[elem.id].data),null,2) : undefined;
              return (
                <div key={elem.id} id={elem.id} style={inlineStyle} title={data ? data : `Last updated: ${updatedAt}`}>{b[elem.id].state}</div>
              )
            })}
          </div>
        </>
      }
    </>
  );
}

export default Page