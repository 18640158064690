export const listDatasets = /* GraphQL */ `
  query Datasets {
    datasets {
      id
      name
      mode
    }
  }
`;

export const mnemonicsByDataset = /* GraphQL */ `
  query MnemonicsByDataset(
    $datasetId: ID
    $limit: Int
    $nextToken: String
  ) {
    mnemonicsByDataset(
      datasetId: $datasetId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        state
      }
      nextToken
    }
  }
`;

export const listBerths = /* GraphQL */ `
  query ListBerths(
    $limit: Int
    $nextToken: String
  ) {
    listBerths(limit: $limit, nextToken: $nextToken) {
      items {
        id
        state
        data
        updatedAt
      }
      nextToken
    }
  }
`;

export const berthsByPage = /* GraphQL */ `
  query BerthsByPage(
    $pageId: ID
    $limit: Int
    $nextToken: String
  ) {
    berthsByPage(
      pageId: $pageId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        x
        y
        color
        properties {
          fontSize
          fontFamily
          fontWeight
        }
      }
      nextToken
    }
  }
`;