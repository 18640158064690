import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import HomeIcon from "@material-ui/icons/Home";
import { useDispatch } from "react-redux";
import { setDataset } from "./redux/datasetSlice";
import { setMnemonics, clearMnemonics } from "./redux/mnemonicsSlice";
import { setBerths, clearBerths } from "./redux/berthsSlice";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { mnemonicsByDataset, listBerths } from "./api/queries";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    flexGrow: 1,
  },
  header: {
    textAlign: "center"
  },
  width: {
    width: "20%",
  },
  margin: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fab: {
    position: "fixed",
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 5,
  },
}));

const Mode = (props) => {
  const { history, mode, linkField } = props;
  const { datasetId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loadingM, setLoadingM] = useState(true);
  const [loadingB, setLoadingB] = useState(true);
  const [buttons, setButtons] = useState();

  useEffect(() => {
    const initData = async () => {
      try {
        console.log(`Downloading: ${datasetId}/dataset.json...`)
        const result = await Storage.get(`${datasetId}/dataset.json`, {
          download: true,
          progressCallback(progress) {
            console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
          }
        });
        result.Body.text().then(content => {
          const jsonContent = JSON.parse(content);
          dispatch(setDataset(jsonContent));
          setButtons(jsonContent[`${mode}s`]);
        });
      } catch (error) {
        console.log(error);
        setButtons([]);
      }
    };
    initData();
  }, [dispatch, datasetId, mode]);

  //fetch initial list of mnemonics
  useEffect(() => {
    const initMnemonics = async () => {
      console.log("clear mnemonics");
      dispatch(clearMnemonics());
      console.log("fetching mnemonics");
      try {
        let nextToken = null;
        do {
          const response = await API.graphql(graphqlOperation(mnemonicsByDataset, { limit: 500, nextToken, datasetId}));
          nextToken = response.data.mnemonicsByDataset.nextToken;
          console.log(`Fetched ${response.data.mnemonicsByDataset.items.length} mnemonics...`);
          if (response.data && response.data.mnemonicsByDataset) {
            dispatch(setMnemonics(response.data.mnemonicsByDataset.items.reduce((a,x) => ({...a, [x.id.substring(datasetId.length+1)]: parseInt(x.state, 10)}), {}) ));
          }
        } while (nextToken);
        setLoadingM(false);
      }
      catch (error) {
        console.log("error fetching mnemonics", error);
      }
    };
    initMnemonics();
  }, [dispatch, datasetId]);

  //fetch initial list of berths
  useEffect(() => {
    const initBerths = async () => {
      console.log("clear berths");
      dispatch(clearBerths());
      console.log("fetching berths");
      try {
        let nextToken = null;
        do {
          const response = await API.graphql(graphqlOperation(listBerths, { limit: 500, nextToken}));
          nextToken = response.data.listBerths.nextToken;
          console.log(`Fetched ${response.data.listBerths.items.length} berths...`);
          if (response.data && response.data.listBerths) {
            dispatch(setBerths(response.data.listBerths.items.reduce((a,x) => ({...a, [x.id]: { state: x.state, updatedAt: x.updatedAt, data: x.data }}), {}) ));
          }
        } while (nextToken);
        setLoadingB(false);
      }
      catch (error) {
        console.log("error fetching berths", error);
      }
    };
    initBerths();
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <style>{`body { background-color: black}; }`}</style>
      </Helmet>
      <Backdrop className={classes.backdrop} open={loadingM || loadingB}>
        <CircularProgress color="primary" />
      </Backdrop>
      <div className={classes.fab}>
        <Fab color="secondary" aria-label="home">
          <HomeIcon onClick={() => history.push(`/`)} />
        </Fab>
      </div>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <Grid container alignItems="center" direction="column" spacing={2}>
            <Grid className={classes.width}>
              <Typography variant="h4" className={classes.header}>Choose a {mode}:</Typography>
            </Grid>
            {buttons && buttons.map((item) => {
              const buttonText = item.description ? item.description : item.name ? item.name : item.viewType ? `${item.viewType} - ${item.id}` : item.id;
              return (
                <Grid key={item.id} className={classes.width}>
                  <Button
                    className={classes.margin}
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{justifyContent: "flex-start"}}
                    startIcon={<DesktopWindowsIcon style={{ fontSize: 80 }} />}
                    onClick={() => history.push(`/${datasetId}/view/${item[linkField]}`)}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Mode
